import * as React from "react"
import { Link } from 'gatsby'
import * as style from "./pagination.module.css"

const Pagination = ({ pageContext }) => {
    const { numberPages, currentPage } = pageContext
    console.log("むm", numberPages)
    return (
        <p className={style.paginationWrapper}>
            {Array.from({ length: numberPages }, (_, i) => (
                <Link key={`pagination-number${i + 1}`} to={`/${i === 0 ? "" : i + 1}`} style={{                                          
                        color: i + 1 === currentPage ? '#fff' : '#00aeef',
                        background: i + 1 === currentPage ? '#00aeef' : '',}}
                >
                {i + 1}
                </Link>
            ))}
        </p>
    )
}

export default Pagination