import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
//import Menu from "../components/menu"
import Pagination from "../components/pagination"
import styles from './index.module.css'

const RootIndex = (props, { pageContext }) => {
  const siteTitle = props.data.site.siteMetadata.title
  const posts = props.data.allContentfulBlogPost.edges
  const [author] = props.data.allContentfulPerson.edges
  const image = props.data.allImageSharp.edges[0].node.fluid
  const categories = ["news", "reviews", "previews", "interviews", "hardware"]
/*   const selectedCategory = posts.filter(post=>{
    if(post.node.tags[0] === pageContext.category) return true
    else return false
  }) */
  return (
    <Layout location={props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} image={image}/>
          <div className="wrapper">
{/*           {pageContext.category ? 
              <h2 className="section-headline">
                  {pageContext.category.charAt(0).toUpperCase() + pageContext.category.slice(1)}
              </h2>  
              :
              null
              
            }
            <Menu categories={categories} location={props.location} categoryName={pageContext.category}/> */}
            <div style={{paddingBottom: "13px"}}></div>
            {/* <p className={styles.contactTitle}><Link to='/contact'>Contact Us</Link></p> */}
{/*             {pageContext.category ?
              <ul className="article-list">
                {selectedCategory.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  )
                })}
              </ul>
            : */}
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  )
                })}
              </ul>
{/*             } */}
          <Pagination pageContext={props.pageContext}/>
          </div>
        </div>
      </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery ($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
        }
      }
    }
    allImageSharp(filter: {fluid: {originalName: {regex: "/gamersmancave/"}}}) {
      edges {
        node {
            fluid(maxWidth: 2000){
              ...GatsbyImageSharpFluid
              originalName
          }
        }
      }
    }
  }
`
