import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <Link to={`/blog/${article.slug}`} className={styles.previewAnchor}>
      <div className={styles.imgcontainer}>
        <Img alt="" fluid={article.heroImage.fluid} />
        {article.tags &&
          article.tags.slice(0, 1).map(tag => (
            <p className={styles.tag} key={tag}>
              {tag.charAt(0).toUpperCase() + tag.slice(1)}
            </p>
        ))}
      </div>
      <h3 className={styles.previewTitle}>{article.title}</h3>
      <small>{article.publishDate}</small>
      <div
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
    </Link>
  </div>
)
