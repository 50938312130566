import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './hero.module.css'

export default ({ data, image}) => (
  <div className={styles.hero}>
    <Link to="/">
    <Img fluid={image} className={styles.heroImage} alt="Header Image"/>
    <div className={styles.heroDetails}>
      {/* <h3 className={styles.heroHeadline}>{data.name}</h3> */}
      {/* <p className={styles.heroTitle}>{data.title}</p> */}
      <p className={styles.heroDescription}>{data.shortBio.shortBio}</p>
    </div>
    </Link>
  </div>
)
